import axios from 'axios'

// We use a custom instance of axios since the global instance is already configured in api.js
const client = axios.create()

// Config defaults
client.defaults.baseURL = import.meta.env.VUE_APP_SW_V2_SERVER_URL
client.defaults.headers.post['Content-Type'] = 'application/json'
client.defaults.headers.common['X-Api-Token'] = import.meta.env.VUE_APP_LOCAL_SW_SERVER_API_TOKEN
client.defaults.headers.common.Accept = 'application/json'

export default {
  getOperator(params) {
    return client.get(`/operator?email=${params.email}`)
  },
  getOperators() {
    return client.get(`/operators`)
  },
  getTeams() {
    return client.get(`/teams`)
  },
  getTeamRoles() {
    return client.get(`/teams/roles`)
  },
  getExpertises() {
    return client.get(`/operators/expertise`)
  },
  registerOperatorTeam(data) {
    return client.post(`/operators/team`, data)
  },
  changeOperatorRole(data) {
    return client.post(`/teams/${data.teamId}/operators/${data.operatorId}/role`, data.payload)
  },
  changeOperatorExpertises(data) {
    return client.post(`/operators/expertise`, data)
  },
  changeClientEmail(data) {
    return client.post(`/clients/${data.clientId}/change-email`, data.payload)
  },
  changeUserLocale(data) {
    return client.post(`/clients/${data.clientUuid}/change-language`, data.payload)
  },
  changeUserGender(data) {
    return client.post(`/clients/${data.clientId}/change-gender`, data.payload)
  },
  changeUserBirthdate(data) {
    return client.post(`/clients/${data.clientId}/change-birthdate`, data.payload)
  },
  changeUserPhone(data) {
    return client.post(`/clients/${data.clientId}/change-phone`, data.payload)
  },
  changeUserAddress(data) {
    return client.post(`/clients/${data.clientId}/change-address`, data.payload)
  },
  changeUserName(data) {
    return client.post(`/clients/${data.clientId}/change-name`, data.payload)
  },
  changeAdvancedDetails(data) {
    return client.post(`/clients/${data.clientId}/change-advanced-details?dry_run=${data.dryRun}`, data.payload)
  },
  changeClientComment(data) {
    return client.post(`/clients/${data.clientId}/change-comment?dry_run=${data.dryRun}`, data.payload)
  },
  changeClientTeam(data) {
    return client.post(`/clients/${data.clientId}/change-team`, data.payload)
  },
  getUserCompanies(id) {
    return client.get(`/clients/${id}/companies`)
  },
  getTargets() {
    return client.get('/targets')
  },
  createTarget(aTarget) {
    return client.post('/targets', aTarget)
  },
  deleteClient(clientId, operatorEmail) {
    return client.delete(`/clients/${clientId}`, { data: { operator_email: operatorEmail } })
  },
  updateTarget(targetId, wins) {
    return client.put(`/targets/${targetId}`, { wins })
  },
  deleteTarget(targetId) {
    return client.delete(`/targets/${targetId}`)
  },
  applyIndexation(contractNumber) {
    return client.put(`/indexations/${contractNumber}`)
  },
  duplicateContractRequest(contractRequestId) {
    return client.post(`/contract_requests/${contractRequestId}/duplication`)
  },
  fetchIndexation(contractNumber) {
    return client.get(`/indexations/${contractNumber}`)
  },
  attachInsurerDocument(contractRequestId, data) {
    return client.post(`/contract_requests/${contractRequestId}/attach_insurer_document`, data)
  },
  detachInsurerDocument(contractRequestId) {
    return client.post(`/contract_requests/${contractRequestId}/detach_insurer_document`)
  },
  fetchClaims(userUuid) {
    return client.get(`/users/${userUuid}/claims`)
  },
  attachInsurerReference(userUuid, claimId, data) {
    return client.post(`/users/${userUuid}/claims/${claimId}/attach_insurer_reference`, data)
  },
  fetchInsurerMessages(userUUID) {
    return client.get(`/users/${userUUID}/messages`)
  },
  changeClientChampion(data) {
    return client.post(`clients/${data.client_id}/change-client-champion`, data.payload)
  },
  listCompanies() {
    return client.get(`/companies`)
  },
  registerCompany(vatNumber, operatorEmail) {
    return client.post(`/companies`, { vat_number: vatNumber, operator_email: operatorEmail })
  },
  getCompany(vatNumber) {
    return client.get(`/companies/${vatNumber}`)
  },
  changeCompanyComment(data) {
    return client.post(`/companies/${data.vatNumber}/change_comment`, data.payload)
  },
  changeCompanyEmail(data) {
    return client.post(`/companies/${data.vatNumber}/change_email`, data.payload)
  },
  changeCompanyClientChampion(data) {
    return client.post(`/companies/${data.vatNumber}/assign_key_account_manager`, data.payload)
  },
  registerClient(payload) {
    return client.post(`/clients`, payload)
  },
  addMember(payload) {
    return client.post(`/members/add`, payload)
  },
  addMemberRoles(data) {
    return client.post(`companies/${data.companyId}/members/${data.clientId}/assign_roles`, data.payload)
  },
  addMemberTitle(data) {
    return client.post(`companies/${data.companyId}/members/${data.clientId}/change_title`, data.payload)
  },
  addMemberPositions(data) {
    return client.post(`companies/${data.companyId}/members/${data.clientId}/change_positions`, data.payload)
  },
  companyContracts(vatNumber) {
    return client.get(`/companies/${vatNumber}/contracts`)
  },
  companyMessages(vatNumber) {
    return client.get(`/companies/${vatNumber}/messages`)
  },
  getCompanyClaims(vatNumber) {
    return client.get(`/companies/${vatNumber}/claims`)
  },
  getCompanyMembers(vatNumber) {
    return client.get(`/companies/${vatNumber}/members`)
  },
  getCompanySignatoryMember(vatNumber) {
    return client.get(`/companies/${vatNumber}/signatory_member`)
  },
  postTransferContract(data) {
    return client.post(`/contracts/${data.contractRef}/transfer_ownership`, data.payload)
  },
  postSearchCompany(payload) {
    return client.post(`/companies/search`, payload)
  },
  removeMember(data) {
    return client.post(`companies/${data.companyId}/members/${data.clientId}/remove`, { operator_id: data.operatorId })
  },
  search(search_term) {
    return client.post('/clients/search', { search_term: search_term })
  },
  postRelationship(payload) {
    return client.post('/relationships/establish', payload)
  },
  discontinuetRelationship(payload) {
    return client.post('/relationships/discontinue', payload)
  }
}
