<template>
  <div style="position: relative">
    <el-autocomplete
      popper-class="c-autocomplete"
      v-model="searchTerm"
      :fetch-suggestions="querySearchAsync"
      :debounce="500"
      @select="handleUserSelect"
      :placeholder="placeholder"
      :ref="clientSearchRef">
      <template v-slot:prepend>
        <el-tooltip :content="$t(`shared.tooltips.${tinySearch ? 'tiny_search' : 'search'}`)" placement="bottom-start">
          <font-awesome-icon :icon="['fas', `${tinySearch ? 'bolt' : 'search'}`]"></font-awesome-icon>
        </el-tooltip>
      </template>
      <template #default="{ item }">
        <div v-if="item.client_id">
          <div class="value">{{ item.first_name }} {{ item.last_name.toUpperCase() }}</div>
          <span class="link">{{ item.email }}</span>
        </div>
        <div v-else>
          <div class="value">{{ item.legal_name }}</div>
          <span class="link">{{ item.company_id }}</span>
        </div>
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
import api from '@/services/api_v2'
const localStorageKey = 'default_search_results'
export default {
  name: 'SearchBar',
  props: {
    value: {
      type: String,
      default: null
    },
    format: {
      type: String,
      default: 'tiny'
    },
    complete: {
      type: Boolean,
      default: true
    },
    clientSearchRef: {
      type: String,
      default: ''
    },
    returnValue: {
      type: Function,
      default: () => {}
    },
    searchUserValue: {
      type: Function,
      default: () => {}
    },
    userNameSelected: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      data: [],
      lastQueryString: '',
      searchTerm: ''
    }
  },
  computed: {
    tinySearch() {
      return this.format === 'tinySearch'
    },
    placeholder() {
      return this.$t('shared.placeholders.client_search')
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.searchTerm = this.value
      }
    },
    searchTerm() {
      this.searchUserValue(this.searchTerm)
    }
  },
  mounted() {
    this._keyListener = function (e) {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        const clientSearchInput = this.$refs.clientSearch
        if (clientSearchInput) {
          const userSelectInput = clientSearchInput.$el.getElementsByTagName('input')[0]
          userSelectInput.focus()
          userSelectInput.select()
        }
      }
    }
    document.addEventListener('keydown', this._keyListener.bind(this))
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this._keyListener)
  },
  methods: {
    async querySearchAsync(queryString, cb) {
      if (!queryString || queryString.length < 5)
        return this.tinySearch ? cb(JSON.parse(localStorage.getItem(localStorageKey) || '[]')) : cb(this.data || [])
      if (queryString === this.lastQueryString) return cb(this.data || [])

      this.lastQueryString = queryString
      const response = await this.search(queryString)

      if (response) {
        if (response.data) {
          this.data = response.data || []
          cb(response.data || [])
          if (!response.data.length) {
            // return email
            this.returnValue(this.searchTerm)
          }
        }
        if (response.status !== 200) {
          this.error = true
          this.$message({
            message: this.$t('shared.messages.user_search_error'),
            type: 'error',
            duration: 10000,
            showClose: true
          })
        }
      }
    },
    async search(queryString) {
      return api.search(queryString)
    },
    disabled(user) {
      return this.complete && user && !user.complete && !this.companyComplete(user)
    },
    companyComplete(user) {
      return user.company && user.company.complete
    },
    handleUserSelect(user) {
      this.handleSaveDefaultUsers(user)
      this.searchTerm = user.fullName
      this.userNameSelected(user.fullName)
      if (this.disabled(user)) {
        this.$message({
          message: this.$t('shared.messages.user_incomplete'),
          type: 'warning',
          duration: 10000,
          showClose: true
        })
        this.searchTerm = ''
      } else {
        this.$emit('select', user)
      }
    },
    handleSaveDefaultUsers(user) {
      const defaultUsers = JSON.parse(localStorage.getItem(localStorageKey) || '[]')
      const index = defaultUsers.findIndex((u) => u.client_id === user.client_id)
      if (index > -1) defaultUsers.splice(index, 1)
      if (defaultUsers.length >= 5) defaultUsers.pop()
      defaultUsers.unshift(user)
      localStorage.setItem(localStorageKey, JSON.stringify(defaultUsers))
    }
  }
}
</script>

<style scoped>
#search-error {
  position: absolute;
  bottom: -10px;
  background: red;
  color: white;
  padding: 0;
  height: auto;
  right: 0;
  line-height: 1.5em;
  padding: 0 10px;
  border-radius: 3px;
  z-index: 5;
}
</style>
