import API from '@/services/api'

export const terminations = {
  index(params) {
    const id = params ? params.user_uuid || params.company_id : ''
    if (params?.company_id) {
      return API.get(`/terminations/company/${id}`)
    } else {
      return API.get(`/terminations/${id}`)
    }
  },
  create(data) {
    return API.post('/terminations', data)
  },
  update(data) {
    return API.put('/terminations', data)
  }
}
