export default {
  add_remove_from_bundled_payment: 'Ajouter ou retirer un contrat du plan de paiement',
  amendment_details: "Détail de l'avenant pour changement de la moto, enregistré par le client",
  not_possible: 'Action impossible pour ce contrat',
  card_titles: {
    actions: 'Actions',
    commercial: 'Informations commerciales',
    general_info: 'Informations générales',
    house: "Informations sur l'habitation",
    policy_owner: "Informations sur le preneur d'assurance",
    risk_object: 'Objets de risque',
    timeline: 'Chronologie des évenements:',
    vehicle: 'Informations sur le véhicule',
    verification: 'Vérification des informations'
  },
  coupling: {
    formula: {
      baloise_bundle_motorbike: 'Client complet (Auto-Habitation-RC familiale) - Moto Baloise',
      baloise_car_motorbike: 'Auto - Moto Baloise',
      baloise_motorbike_motorbike: 'Moto - Moto Baloise'
    },
    role: {
      beneficiary: 'Bénéficiaire du couplage',
      primary: 'Base du couplage'
    }
  },
  create_amendment: 'Enregistrer un nouvel avenant',
  create_car_contract: 'Nouveau contrat auto 🚗',
  create_green_card: "Ajouter un nouveau certificat d'assurance",
  duplicate: 'Dupliquer la simulation',
  duplicate_confirmation: 'Êtes-vous certain de vouloir dupliquer cette simulation ?',
  elements: {
    agent_account: 'Compte producteur:',
    bundled_payments_more_info: 'Gérer les plans paiements',
    company_contract: 'Contrat en société:',
    coupled: 'Couplé:',
    coupling_formula: 'Formule de couplage:',
    coupling_role: 'Rôle de couplage:',
    covers: 'Risques couverts:',
    gross_premium: 'Primes:',
    commission: 'Commissions:',
    insurer: 'Assureur:',
    policy_owner_name: "Preneur d'assurance:",
    policy_grouping_number: 'Numéro de regroupement de polices:',
    reference: 'Référence:',
    operator: 'Vendeur:',
    start_date: "Date de prise d'effet:",
    splitting: 'Fractionnement:',
    stopped_at: 'Date de résiliation effective:',
    stopping_encoded_at: "Date d'encodage de la résiliation",
    stopping_encoded_by: 'Responsable de la résiliation:',
    stopping_reason: 'Raison de la résiliation:'
  },
  link: 'lien',
  linked_to_insurers: {
    label: 'Statut assureur',
    linked: 'Le contrat est lié, des messages assureurs ont été reçus',
    not_linked: "Le contrat n'est pas lié, aucun message assureur n'a encore été reçu",
    terminated: "Le contrat a été marqué comme résilié par l'assureur"
  },
  new: 'Nouveau contrat',
  part_of_bundled_payment: "Fait partie d'un plan de paiement",
  prepopulation: {
    risk_name: 'Assurance '
  },
  reinstate: 'Rétablir le contrat',
  rectification: 'Rectifier le contrat',
  risk_object: {
    alias: 'Alias:',
    already_insured: 'Déjà assuré:',
    details: "Détails de l'objet de risque:",
    entry_into_circulation: 'Entrée dans la circulation',
    info: 'Info:',
    new_car: 'Nouvelle voiture:',
    type: 'Type:'
  },
  risk_objects: {
    bicycle: '🚲',
    boat: '⛵',
    car: '🚗',
    digital_bag: '📱',
    family: '👪',
    house: '🏠',
    house_rental: '🏘️',
    independent: '👔',
    motorbike: '🏍️',
    motorhome: '🚐',
    pension: '🧓',
    personal: '',
    travel: '✈️',
    van: '🚐',
    undefined: '',
    'push-scooter': '🛴',
    civil_liability: '👪',
    civil_liability_hunting: '',
    civil_liability_work_accident: '💼',
    e_reputation: '',
    hospitalization: '🏥',
    legal_expenses: '',
    moto: '🏍️',
    private_savings: '👴',
    quad: '',
    life_independant: '👴',
    travel_assistance: '✈️',
    truck: '🚚',
    b2b: '💼',
    life_ep_lt: '👴',
    life_ep_elt: '👴',
    assistance: '',
    driver_safety: '',
    axa_life_asrd: '🏘️',
    life_asrd: '🏘️',
    ag_life_savings_branch_21: '👴',
    null: '',
    oldtimer: '🚙',
    other: '',
    self_employed_savings: '👴'
  },
  risk_object_names: {
    bicycle: 'Vélo',
    boat: 'Bateau',
    car: 'Voiture',
    digital_bag: 'Digital',
    family: 'Famille',
    house: 'Habitation (propriété)',
    house_rental: 'Habitation (location)',
    independent: 'Indépendant',
    motorbike: 'Moto',
    motorhome: 'Camping-car',
    pension: 'Pension',
    'push-scooter': 'Trottinette',
    personal: 'Personnel',
    travel: 'Voyage',
    van: 'Van',
    undefined: '',
    civil_liability: 'RC Familiale',
    civil_liability_hunting: 'RC chasse',
    civil_liability_work_accident: 'RC accident de travail',
    e_reputation: 'E-réputation',
    hospitalization: 'Hospitalisation',
    legal_expenses: 'Protection juridique',
    moto: 'Moto',
    private_savings: 'Épargne pension',
    quad: 'Quad',
    life_independant: 'Épargne pension indépendant',
    travel_assistance: 'Assistance voyage',
    truck: 'Camion',
    b2b: 'B2B',
    life_ep_lt: 'Epargne long-terme',
    life_ep_elt: 'Epargne pension',
    assistance: 'Assistance',
    driver_safety: 'Assurance conducteur',
    axa_life_asrd: 'Assurance solde restant dû',
    life_asrd: 'Assurance solde restant dû',
    ag_life_savings_branch_21: 'Epargne pension',
    null: '',
    oldtimer: 'Voiture ancêtre',
    other: 'Assurance',
    self_employed_savings: 'Epargne pension indépendent'
  },
  save: 'Enregistrer',
  splittings: {
    half_yearly: 'Semi-annuel',
    monthly: 'Mensuel',
    null: '-',
    trimestrial: 'Trimestriel',
    unspecified: 'Non-spécifié',
    yearly: 'Annuel'
  },
  premium_collection_methods: {
    direct_billing: 'Compagnie',
    broker_billing: 'Yago'
  },
  statuses: {
    simulation: 'Achats en cours',
    waitingForSignature: 'En attente de signature',
    processed: 'Contrats en ordre',
    archivedContracts: 'Contrats résiliés',
    archived: 'Simulations archivées'
  },
  stopping: {
    stopping_schedule: 'Prévue pour le {date}'
  },
  indexation: {
    button_title: "Appliquer l'indexation",
    modal_title: "Êtes vous sûr(e) de vouloir appliquer l'indexation ?",
    base_premium: "L'ancien prix est de {base_premium}€.",
    indexed_premium: "Le nouveau prix est de {indexed_premium}€ suite à l'indexation de {indexation_percentage}%."
  },
  title: 'Contrats à signer',
  total_budget: 'Prime mensuelle: ',
  aml_document_warning: "La fiche d'information AML ne doit être attachée qu'à des contrats vie",
  product_guidance: 'Merci d’indiquer le produit correct ; le client le verra dans son espace client.',
  alias_guidance: 'Attention, ceci sera visible sur l’espace client du client. Soyez précis.',
  form_errors: {
    information_duty: "Attention, tous les contrats vendus par YAGO sont censés avoir un devoir d'information. ",
    client_card: "Attention, chaque client doit avoir au moins une fiche client dès lors qu'il est devenu client",
    quittance:
      "Attention, le client ne recevra pas d'instructions de paiement et risque de tomber en impayé. Vous pouvez la décocher uniquement si vous n'avez pas reçu de quittance de la compagnie (le contrat prendra effet dans 3mois ou +), ou si le client paie par domiciliation bancaire."
  },
  contract_statuses: {
    archived: 'Contrat archivé',
    covered: 'Contrat signé par le client',
    full_package_bundled: 'Groupé dans l’offre multi-contrat',
    processed: 'En ordre',
    suspended: 'Suspendu',
    terminated: 'Résilié',
    voided: 'Nul et non avenu',
    waiting_for_completion: 'Attente données personnelles du client',
    waiting_for_confirmation: 'Attente confirmation',
    waiting_for_details: 'Attente détails clients',
    waiting_for_details_and_verification: 'Attente retour Baloise non-fichage et détails client',
    waiting_for_payment: 'Attente confirmation et paiement',
    waiting_for_selection: 'Attente sélection des garanties',
    waiting_for_signature: 'Attente signature par client',
    waiting_for_validation: 'Attente validation des détails client',
    waiting_for_validation_and_verification: 'Attente retour Baloise et validation détails client',
    waiting_for_verification: 'Attente retour Baloise non-fichage'
  },
  archived_contract_reasons: {
    already_terminated_contract_transferred: 'Contrat transféré déjà résilié',
    amendment: 'Avenant / nouveau contrat (remboursement pro-rata)',
    claim: 'Sinistre (remboursement pro-rata)',
    dispute: 'Contentieux (nécessite un remboursement manuel depuis Chargebee)',
    e_commerce_rights: 'Rétractation 14 jours (nul et non-avenu, remboursement totalité dernier paiement)',
    mistake: 'Erreur souscription (nul et non-avenu, remboursement totalité dernier paiement)',
    premium_increase: 'Hausse tarifaire (remboursement pro-rata)',
    risk_disappearance: 'Disparition du risque (remboursement pro-rata)',
    standard: "Volonté d'arrêt (pas de remboursement)",
    suspension: 'Suspension (interruption du paiement et crédit de primes au pro-rata)',
    unfavorable_transfer: 'Transfert en défaveur (pas de remboursement)',
    unpaid: 'Impayé (après rappels, pas de remboursement)',
    other: 'Autre (pas de remboursement)',
    waiver_by_insurer: "Renon par l'apériteur",
    policy_owner_death: "Décès du preneur d'assurance",
    policy_owner_bankruptcy: "Faillite du preneur d'assurance",
    departure_abroad: 'Départ étranger',
    surrender: 'Rachat (Vie)',
    obligation_to_another_insurer: 'Obligation envers autre assureur',
    risk_aggravation: 'Aggravation du risque',
    activity_cessation: "Cessation d'activités",
    cancelled: 'Annulation'
  },
  create_zendesk_ticket: '+ Nouvelle demande customer care',
  new_contract_audit: '🔍 Auditer contrat',
  audit_info_link: 'https://www.notion.so/seraphin-be/R-ussir-ton-audit-perso-05a861bb1864482fade677d7d6e6bed5',
  transfer_contract: 'Transférer le contrat',
  transfer_contract_modal: {
    title: 'Transférer ce contrat',
    confirmation_disclaimer: 'Êtes-vous sur de vouloir transférer ce contrat vers {name} ?',
    owner_type: 'Transférer vers',
    owner_types: {
      individual: 'Particulier',
      self_employed: 'Indépendant',
      company: 'Entreprise'
    },
    warning_self_employed:
      '⚠️ Attention : vous avez sélectionné un profil indépendant. Assurez-vous que la personne choisie est bien enregistrée en tant que personne morale.',
    warning_b2b:
      "⚠️ Veuillez vérifier que l'entreprise sélectionnée contient bien une première personne de contact et une adresse postale complète."
  },
  create_invoice: '+ Créer facture',
  invoice_info_link: 'https://www.notion.so/Contrat-encaissement-YAGO-ea8be02a82eb4eae80addd07e518625d?pvs=24'
}
