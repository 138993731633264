export default {
  actions: {
    add_belonging: 'Risico-object toevoegen',
    add_document: 'Document toevoegen',
    add_green_card: 'Verzekeringsbewijs toevoegen',
    archive: 'Archiveren',
    cancel: 'Annuleren',
    cancelled: 'Actie geannuleerd.',
    confirm: 'Bevestigen',
    confirmation_disclaimer: 'Weet u zeker dat u deze actie wilt uitvoeren?',
    offer_closure_confirmation: 'Wilt u de bijbehorende offerte sluiten voordat u deze simulatie valideert?',
    copy_link: 'Link voor handtekening kopiëren',
    complete_life_procedure: 'Levensprocedure voltooien',
    delete: 'Verwijderen',
    delete_belonging: 'Verwijderen (alleen indien niet gekoppeld aan een lopend contract/aanbod)',
    edit: 'Bewerken',
    edit_contract: 'Contract bewerken',
    remove: 'Verwijderen',
    rgpd_removal: 'Klant verwijderen (AVG)',
    edit_user_belongings: 'Risico-objecten bewerken',
    edit_user_profile: 'Klantprofiel bewerken',
    edition: 'Editie',
    fill_documents: 'Documenten invullen',
    go_to: 'Ga naar...',
    loading: 'Bezig met laden...',
    mark_as_lost: 'Markeren als verloren',
    mark_as_paid: 'Markeren als betaald',
    mark_as_waiting_for_contract: 'Markeren als verwerkt, wachtend op contract',
    pipedrive: 'Pipedrive Deal',
    pipedrive_link: 'Pipedrive-link van de klant',
    reactivate: 'Reactiveren',
    save: 'Opslaan',
    see_details: 'Details bekijken',
    see_document: 'Document bekijken',
    see_json_details: 'Technische details van het object bekijken',
    see_more: 'Meer zien',
    send: 'Versturen',
    send_for_completion: 'Versturen voor voltooiing',
    send_for_signature: 'Versturen voor ondertekening',
    send_reminder: 'Herinnering naar klant sturen',
    sending: 'Versturen...',
    signatory_member: 'Ondertekenend lid',
    signature_reminder: 'Herinnering voor handtekening versturen',
    solve: 'Markeren als afgesloten/verwerkt',
    solve_for_later_date: 'Markeren als verwerkt voor een latere datum',
    solve_for_next_year: 'Vervaldatum plus 1 jaar',
    success: 'Actie succesvol uitgevoerd',
    terminate: 'Opzeggen',
    transfer_to_baloise: 'Contract overzetten',
    unsubscribe: 'Uitschrijven',
    upload_reminder: 'Herinnering voor uploaden van documenten sturen',
    add_coupling_primary: 'Contract als basis voor koppeling toevoegen',
    add_to_full_package_bundle: 'Toevoegen aan het multi-contract aanbod',
    baloise_car_pack_validation_confirmation: 'Ik bevestig de gegevens van de klant',
    become: 'Oude My Yago',
    become_my: 'My Yago',
    cancel_stopping: 'Opzeg annuleren',
    confirmation_label: 'Bevestigen',
    couple: 'Contract koppelen',
    couple_as_motorbike_primary: 'Contract koppelen',
    create_amendment: 'Nieuw aanhangsel toevoegen',
    create_contract: 'Bevestigen en contract opmaken',
    create_green_card: 'verzekeringsbewijs toevoegen',
    mail: 'Email aanmaken',
    reject_for_bad_customer: 'Afwijzen wegens slechte klant',
    reject_for_bad_input: 'Terug naar upload documenten',
    remove_from_full_package_bundle: 'Verwijderen van aanbod',
    renew_green_card: 'Vernieuwen verzekeringsbewijs',
    reset_password: 'Mail reset WW versturen',
    resume_purchase: 'Aankoop hervatten',
    stop: 'Opzeggen / Opschorten / Nadelige transfer',
    stopping_date: 'Daatum waarop contract zal worden opgezegd.',
    stopping_reason: 'Opzegreden ?',
    upsell: 'Contract Upsellen',
    validate: 'Upgeloade documenten bevestigen',
    validate_amendment: 'Aanhangsel bevestigen',
    verify: 'Contract goedkeuren (niet gemeld)'
  },
  assertions: {
    yes: 'Ja',
    no: 'Nee'
  },
  commercial_data: {
    chargebee: 'Chargebee:',
    subscription_link: 'Link naar abbonement'
  },
  company_symbol: '💼 ',
  claims: {
    add_claim_reference: 'Referentie verzekeraar toevoegen',
    reference: 'Referentie'
  },
  documents: {
    existing_contract: 'Bestaand contract',
    existing_front_document: 'Voorzijde van bestaand document',
    existing_back_document: 'Achterzijde van bestaand document',
    existing_invoice_document: 'Bestaande aankoopfactuur',
    existing_grey_card_front: 'Voorzijde van huidig kentekenbewijs',
    existing_grey_card_back: 'Achterzijde van huidig kentekenbewijs',
    back: 'Achterzijde',
    claim_statements: 'Schadeattesten:',
    contract_pdf: 'Contract',
    insurer_policy_document_url: 'Contract',
    insurer_policy_document_icon_explanation: 'Ontvangen door verzekeraar bericht',
    insurer_total_premium_match_explanation: 'Hetzelfde als het bericht van de verzekeraar',
    insurer_total_premium_mismatch_explanation: 'Anders dan het bericht van de verzekeraar',
    missing_contract: '⚠️ Ontbrekend contract',
    offer_pdf: 'Document van het aanbod:',
    driving_licence: 'Rijbewijs:',
    first_quittance: 'Eerste kwitantie',
    aml_document: 'AML-informatieblad',
    indexing_quittance: 'Kwitantie {year}',
    front: 'Voorzijde',
    grey_card: 'kentekenbewijs:',
    identity_card: 'Identiteitskaart:',
    invoice: 'Aankoopfactuur:',
    last_amendment_pdf: 'PDF van laatste wijziging:',
    registration_request: 'Aanvraag tot inschrijving:',
    signed: 'Ondertekend:',
    statement: 'Verklaring',
    title: 'Documenten',
    unsigned: 'Niet ondertekend:',
    upload: {
      amendment: 'Wijzigingsdocument',
      aml: 'AML-informatieblad',
      bank_transfer: 'Overschrijving',
      claim_statement: 'Schadeattest',
      company_offer: 'Aanbod van de maatschappij',
      contract: 'Contract',
      green_card: 'Verzekeringscertificaat',
      grey_card_back: 'Kentekenbewijs (achterzijde)',
      grey_card_front: 'Kentekenbewijs (voorzijde)',
      invoice: 'Aankoopfactuur',
      quittance: 'Kwitantie',
      registration_request: 'Aanvraag voor inschrijving'
    }
  },
  evolve_labels: {
    archived: 'gearchiveerd',
    lost: 'verloren',
    reactivated: 'gereactiveerd',
    solved: 'afgesloten',
    solved_for_another_date: 'verwerkt voor een andere datum',
    waiting_for_contract: 'verwerkt en wachtend op contract'
  },
  false: 'Nee',
  false_symbol: '❌',
  forms: {
    couple_form: {
      beneficiary_role: 'Begunstigde van de koppeling',
      formula_question: 'Wat is de koppelingsformule?',
      primary_role: 'Basis van de koppeling',
      role_question: 'Wat is de rol van dit contract in de koppeling?'
    },
    disclaimers: {
      delete_relationship: 'Weet u zeker dat u deze relatie wilt verwijderen?',
      address:
        'Let op, je hebt zojuist het adres gewijzigd. De klant is waarschijnlijk verhuisd. Zorg er zeker voor dat: <li>De adressen bij elke maatschappij worden gewijzigd zodat de klant zijn volgende kwitanties ontvangt</li>{addressHasHouseContractMessage}',
      address_has_house_contract_message:
        '<li>Het contract {contractNumber} bij {insurer} van de klant wordt aangepast/opgezegd.</li>',
      confirm: 'Ik bevestig de bovenstaande punten',
      email:
        'Weet je zeker dat je het e-mailadres van deze klant wilt wijzigen? Wijzig een e-mailadres alleen als je zeker bent dat dit moet en correct is!',
      mobile_phone: 'Dit verandert het nummer voor de lopende overdrachten, opzeggingen en SEPA niet !',
      terminations: {
        confirm_another_contract_ready: {
          future_policy_end_date: 'Ik bevestig dat er een nieuw contract is opgemaakt dat op deze datum begint.',
          past_policy_end_date:
            'Ik bevestig dat er een ander contract is opgemaakt dat vandaag begint om het risico van de klant te dekken'
        },
        confirm_contract_status: {
          future_policy_end_date:
            'Ik ben me ervan bewust dat het contract afloopt op de vervaldatum - dat is {policy_end_date}.',
          past_policy_end_date:
            'Ik ben me ervan bewust dat het contract onmiddellijk na ondertekening door de klant eindigt - de vervaldatum is {policy_end_date}.'
        },
        confirm_sending_to_old_company:
          'Ik ben me ervan bewust dat de opzegging maximaal 3 maanden na ontvangst, door de klant, van de nieuwe kwitantie naar de oude verzekeraar kan worden gestuurd. Als de klant het een maand voor de vervaldatum van het contract heeft ontvangen, moet de opzegging dus binnen maximaal 2 maanden worden verzonden.'
      },
      unfit:
        "Ben je zeker? Het Ardor-profiel van deze klant zal worden gemarkeerd als geweigerd, wat aangeeft aan al uw collega's dat Yago niet verder wil samenwerken met deze klant"
    },
    radio: {
      company: 'Zelfstandige',
      no: 'Nee',
      private: 'Particulier',
      yes: 'Ja'
    },
    titles: {
      address: 'Adres',
      company_address: 'Adres van de onderneming',
      company_data: 'Bedrijfsgegevens',
      contact_data: 'Contactgegevens',
      edit_belonging: 'Risico-object bewerken',
      edit_car_vehicle: 'Auto bewerken',
      edit_campaign: 'Campagne bewerken',
      edit_house_belonging: 'Woning bewerken',
      edit_motorbike_vehicle: 'Motor bewerken',
      edit_target: 'Doel bewerken',
      edit_user: 'Gebruikersgegevens bewerken',
      payment_data: 'Betalingsgegevens',
      personal_data: 'Persoonlijke gegevens',
      risk_objects: 'Risico-objecten van de klant'
    },
    tooltips: {
      incomplete_company_client_data:
        'De ondernemingsgegevens van deze klant moeten compleet zijn om dit veld te gebruiken',
      incomplete_data:
        'De persoonlijke gegevens van de klant of die van zijn onderneming moeten compleet zijn om dit veld te gebruiken.',
      incomplete_default_client_data:
        'De gegevens van de particuliere klant moeten compleet zijn om dit veld te gebruiken',
      insurer_email:
        'Als u de maatschappij niet kunt vinden, betekent dit dat het bijbehorende e-mailadres niet in Ardor is opgenomen',
      no_signatory_member: 'Dit bedrijf heeft geen ondertekenaar',
      notify: {
        no: 'Nee: de gebruiker ontvangt rond 18.00 uur een e-mail met andere mogelijke meldingen.',
        yes: 'Ja: de gebruiker ontvangt direct een e-mail. ⚠️ Als het document om 18.00 uur niet is ondertekend, zal het ook in de meldingse-mail worden opgenomen.'
      },
      vat_number: 'Een Belgisch BTW-nummer moet beginnen met BE0 of BE1. Ardor accepteert dat u de BE achterwege laat.'
    },
    total: 'Totaal',
    validations: {
      accidents: {
        end_date: 'De schadedatum moet na de startdatum van de periode liggen',
        start_date: 'De schadedatum moet voor de einddatum van de periode liggen'
      },
      between: 'Moet tussen {min} en {max} liggen',
      birthdate: {
        too_old: 'Moet jonger dan 100 jaar zijn',
        too_young: 'Moet ouder dan 18 jaar zijn'
      },
      chassis_number: {
        invalid: 'Moet bestaan uit 17 alfanumerieke karakters'
      },
      contract: {
        wrong_user: 'Dit contract {contract} is niet op naam van de klant {user}'
      },
      description: 'De beschrijving moet langer zijn',
      document: {
        client_card_updated: 'Een pdf is vereist omdat er een klantenfiche zal worden gegenereerd',
        covers_updated: 'Een pdf is vereist omdat de dekkingen zijn gewijzigd',
        information_duty_updated: 'Een pdf is vereist omdat er een informatieplicht zal worden gegenereerd'
      },
      email: 'Het e-mailadres is niet geldig',
      end_date: 'De einddatum moet na de startdatum liggen',
      green_card: {
        valid_from: 'De geldigheidsdatum moet na de startdatum van het contract ({contractStartDate}) liggen',
        valid_until: 'De einddatum van de geldigheid moet na de startdatum liggen'
      },
      invalid_date: 'De datum is niet geldig',
      invalid_car_bcp_policy_type: 'Het is niet mogelijk om een overdracht te doen naar Auto BCP',
      invalid_policy_type: 'Deze dekking kan niet worden geselecteerd in dit proces',
      reference: 'Ongeldig formaat. Alleen cijfers en hoofdletters zijn toegestaan.',
      required: 'Dit veld is verplicht',
      seraphin_email: 'Het e-mailadres voldoet niet aan het Yago-formaat',
      start_date: 'De startdatum moet voor de einddatum liggen'
    },
    warnings: {
      missing_field: 'Ontbrekend veld ⚠️',
      files: {
        limit_exceeded: 'Verwijder eerst het bestand om een ander te kunnen toevoegen',
        too_large: 'Het geüploade bestand is te groot'
      },
      life_contract_information_duty:
        'Let op, u encodeert een levensverzekeringscontract or Schuldsaldoverzekering. Zorg ervoor dat de bijbehorende informatieplicht correct is gecreerd. Dit is een wettelijke verplichting van de FSMA.'
    }
  },
  genders: {
    emoji: {
      female: '👩 ',
      male: '👨 ',
      null: '👤 '
    },
    female: 'Vrouw',
    male: 'Man',
    null: 'Onbekend'
  },
  json_details_title: 'Technische details van het object in JSON-formaat 🤓',
  labels: {
    client_type: 'Type klant',
    associated_documents: 'Persoonlijke documenten',
    address: 'Adres',
    age: 'Leeftijd',
    alarm_installed: 'Alarm',
    alias: 'Alias',
    already_insured: 'Al verzekerd',
    amendment_number: 'Aanhangselnummer',
    amendment_pdf: 'Aanhangsel PDF uploaden',
    aml: 'AML-informatieblad',
    amount_due: 'Verschuldigd bedrag',
    assistance_formula: 'Assistentieformule',
    attachment: 'Bijlage',
    audit_validated: 'Audit gevalideerd',
    axa_offer_reference: 'Phoenix (Axa) simulatiereferentie',
    back_document: 'Achterzijde document',
    baloise_car_pack_disclaimer: 'Dit is een BCP-simulatie - gebruik deze referenties niet op MyBaloise :)',
    baloise_car_pack_label: '~ Baloise Car Pack ~',
    bank_transfer: 'Overschrijving',
    bic: 'BIC van de bank',
    birthdate: 'Geboortedatum',
    brand: 'Merk',
    build_year: 'Bouwjaar',
    bundled_payment: 'Betalingsplan',
    bundled_payment_payment_due_at: 'Betaling mislukt op',
    bundled_payments: 'Betalingsplannen',
    category: 'Categorie',
    change_email: 'E-mail wijzigen?',
    chargebee: 'Chargebee',
    chargebee_subscription_link: 'Link naar inschrijving',
    chassis_number: 'Chassisnummer',
    city: 'Stad',
    civil_status: 'Burgerlijke staat',
    claims: 'Claims',
    claims_statement_declared_years: 'Aangegeven jaren schadehistoriek',
    claims_statement_uploaded_years: 'Geüploade jaren schadehistoriek',
    client: 'Klant',
    client_card: 'Klantenfiche',
    company: 'Onderneming',
    content_value: 'Waarde inboedel',
    contract: 'Contract',
    contract_number: 'Contractnummer',
    contract_request: 'Simulatie',
    country: 'Land',
    coupled: 'Gekoppeld',
    coupling: 'Koppeling',
    coupling_formula: 'Koppelingsformule',
    coupling_role: 'Koppelingsrol',
    cover: 'Dekking(en)',
    cover_end_date: 'Einddatum dekking',
    covers: 'Dekkingen',
    description: 'Beschrijving',
    details: 'Details',
    document_type: 'Documenttype',
    driving_licence_date: 'Datum rijbewijs',
    email: 'E-mail',
    encoded: 'Ingegeven',
    end_date: 'Vervaldag',
    engine_displacement: 'Cilinderinhoud',
    entry_into_circulation: '1ste Ingebruikname',
    family_name: 'Achternaam',
    finalized: 'Afgerond?',
    financial_data: 'Financiële gegevens',
    first_name: 'Voornaam',
    fix_phone: 'Vaste telefoon',
    former_policy_number: 'Nummer van vorige polis (zie PDF)',
    free_message: 'Vrij bericht',
    front_document: 'Voorzijde document',
    gender: 'Geslacht',
    green_card: 'Verzekeringscertificaat',
    green_card_pdf: 'PDF van het verzekeringscertificaat uploaden',
    gross_premium: 'Jaarlijkse premies',
    has_company: 'Heeft de klant een onderneming?',
    household_type: 'Type huishouden',
    iban: 'IBAN-nummer',
    information_duty: 'Informatieplicht BOAR',
    insurance_request_delay: 'Urgentie verzekeringsaanvraag',
    insured_risk: 'Verzekerd risico',
    insurer: 'Verzekeraar',
    language: 'Contacttaal',
    last_name: 'Achternaam',
    legal_name: 'Wettelijke naam',
    legal_person: 'Rechtspersoon (bedrijf)',
    licence_plate: 'Kenteken',
    main_cover: 'Hoofddekking',
    main_driver: 'Hoofdbestuurder',
    manufacturer: 'Merk',
    message: 'Bericht',
    messages: 'Berichten',
    mobile_phone: 'Telefoon',
    model: 'Model',
    modifications: 'Instructies en voorzorgsmaatregelen',
    month: 'Maand',
    months: 'maanden',
    motorbike_formula: 'Voertuigformule',
    my_baloise_proposal_reference: 'Referentie van het myBaloise-aanbod',
    my_baloise_references_header: 'Referenties',
    my_baloise_quote_reference: 'myBaloise simulatiereferentie',
    name: 'Naam',
    national_registry_number: 'Nationaal registratienummer',
    natural_person: 'Natuurlijk persoon (individu)',
    nature: 'Natuur',
    new_insurer: 'Ten gunste van',
    not_applicable: 'N.v.t.',
    not_reviewed: 'Nog niet nagekeken',
    notify: 'Gebruiker notificeren',
    occasional_driver: 'Occasionele bestuurder',
    occurred_at: 'Datum van het schadegeval',
    old_insurer: 'Opgezegde verzekeraar',
    on_files: 'Gemeld',
    operator: 'Business developer',
    origin: 'Bron',
    owner_title: 'Functie binnen de onderneming',
    payment_due_at: 'Betaling verschuldigd op',
    place_of_birth: 'Geboorteplaats',
    policy_end_date: 'Einddatum polis',
    policy_number: 'Polisnummer',
    policy_owner: 'Verzekeringsnemer',
    politically_exposed: 'Politiek blootgesteld',
    pre_tax_price: 'Prijs excl. btw',
    premium_collection: 'Incasso',
    price_increase: 'Tariefverhoging',
    product: 'Product',
    profession: 'Beroep',
    professional_status: 'Beroepsstatus',
    quittance: 'Kwitantie',
    residence_type: 'Type woning',
    residence_value: 'Geschatte waarde',
    resident_type: 'Type bewoner',
    responsibility: 'Aansprakelelijkheid',
    risk_object: 'Risico-object',
    second_hand: 'Tweedehands',
    secondary_driver: 'Secundaire bestuurder',
    send_email_to_insurer: 'E-mail naar verzekeraar sturen',
    seniority_formula: 'Leeftijdsformule',
    splitting: 'Splitsing',
    standard_message: 'Standaardbericht',
    start_date: 'Ingangsdatum',
    status: 'Status',
    street_box: 'Bus',
    street_name: 'Straat',
    street_number: 'Nummer',
    subject: 'Onderwerp',
    sworn_statement: '⚠️ Verklaring op eer',
    target: 'Maandelijkse doelstelling',
    temporary: 'Tijdelijk verzekeringscertificaat?',
    termination: 'Opzegging',
    theft_formula: 'Diefstalformule',
    theft_value: 'Bedrag bij diefstal',
    type: 'Type',
    time: 'Ontvangen op',
    time_between: 'om',
    time_country: 'nl',
    unfit: 'Klant geweigerd',
    unfit_reason: 'Reden van weigering',
    unfit_comment: 'Beschrijving',
    unpaid: 'Onbetaald',
    unpaids_bundled_payment: 'Onbetaalde betalingsplannen',
    unpaids_contracts: 'Onbetaalde contracten',
    updated_at: 'Bijgewerkt',
    upload_document: 'Upload {name} (max {max_file_size} Mb)',
    upload_grey_card_back: 'Upload achterzijde kentekenbewijs (max {max_file_size} Mb)',
    upload_grey_card_front: 'Upload voorzijde kentekenbewijs (max {max_file_size} Mb)',
    uploaded_on_ardor: 'Geüpload op Ardor',
    usage: 'Gebruik',
    valid_from: 'Geldig vanaf...',
    valid_until: 'Geldig tot...',
    vat_number: 'Btw-nummer',
    vehicle: 'Voertuig',
    vivium_quote_reference: 'Vivium-aanbiedingsreferentie',
    waiting_for_signature: 'Contract te ondertekenen?',
    endorsement_requires_signature: 'Aanhangsel te ondertekenen?',
    will_for_full_package_client: 'Wil een gebundeld pakket',
    wins: 'Geassocieerde win(s)',
    years: 'jaren',
    zip_code: 'Postcode',
    events: 'Evenementen',
    roles: 'Rollen',
    added_at: 'Toegevoegd op',
    positions: 'Functies',
    title: 'Titel'
  },
  messages: {
    action_cancelled: 'Actie geannuleerd.',
    rgpd_removal_disabled: 'Het is niet toegestaan een klant die een contract heeft te verwijderen',
    confirm: {
      add: 'Weet je zeker dat je wil toevoegen: {object}?',
      reinstate: 'Weet je zeker dat je het contract wilt herstellen?',
      delete: 'Weet je zeker dat je wil verwijderen: {object}?',
      delete_client: 'Weet u zeker dat u deze client wilt verwijderen?<br>Deze actie is <b>onomkeerbaar</b>',
      email:
        '<b>Weet je zeker dat je deze e-mail wil versturen?</b><br>Wees voorzichtig om de gebruiker niet te spammen: controleer vooraf in zijn/haar evenementen de reeds verzonden e-mails.<br>Ontvanger: <b>{to}</b>',
      status_update: 'Weet je zeker dat je dit {object} wilt markeren als {label}?',
      terminate:
        '<b>Wil je een opzegnotificatie versturen?</b><br>Na bevestiging wordt je doorgestuurd naar het klantprofiel om het contract handmatig te beëindigen.',
      terminate_bundled_payment:
        '<b>Wil je een notificatie voor het stopzetten van het betalingsplan versturen?</b><br>Na bevestiging wordt je doorgestuurd naar het klantprofiel om het betalingsplan handmatig te stoppen.',
      update: 'Weet je zeker dat je wilt wijzigen: {object}?',
      rectify: 'Weet je zeker dat je wilt corrigeren: {object}?'
    },
    encoding_problem: 'Ingaveprobleem.',
    link_copied: 'Gekopieerd naar klembord',
    loading: {
      add: 'Toevoegen van: {object} wordt geladen...',
      delete: 'Verwijderen van: {object} wordt geladen...',
      update: 'Bijwerken van: {object} wordt geladen...',
      rectify: 'Correctie van: {object} wordt geladen...'
    },
    removed_redirect: 'Het verwijderen is gelukt, u wordt doorgestuurd',
    missing_user_data:
      'Let op, je moet eerst de volgende velden invullen: nationaal nummer, beroep en politiek blootgestelde persoon',
    ongoing_sepa_mandate:
      'Let op, er zijn een of meer SEPA-mandaten die nog niet zijn gearchiveerd/geannuleerd. Gelieve deze te archiveren voordat je een nieuwe aanmaakt',
    send_cancelled: 'Verzending geannuleerd',
    synchronization_error:
      'Er is een synchronisatiefout opgetreden. Herlaad de pagina en dien het formulier opnieuw in.',
    technical_problem: '🤭 Oeps...klein technisch probleem. Neem contact op met de techies.',
    remove_disclaimer:
      'Weet u zeker dat u deze gebruiker wilt verwijderen?<br/> Houd er rekening mee dat deze actie onomkeerbaar is',
    user_incomplete:
      'De basisinformatie van de klant is niet compleet. Controleer of zijn/haar basisgegevens correct zijn ingevuld (naam, voornaam, telefoonnummer, geboortedatum, adres).',
    insurer_has_no_transfer_email: 'Deze verzekeraar beschikt niet over een e-mailadres voor overboekingen',
    user_search_error: 'Er is een fout opgetreden bij het zoeken op gebruiker.',
    company_search_error: 'Er is een fout opgetreden tijdens het zoeken naar het bedrijf.',
    company_incomplete:
      "La compagnie ne dispose pas d'un membre signataire dont les informations sont remplies. Veuillez vérifier si les infos de base du membre signataire (nom, prénom, titre) et de la compagnie (nom légal, adresse) ont bien été complétées."
  },
  null: '-',
  null_symbol: '-',
  placeholders: {
    bicycle: 'Merk + model invoeren',
    car: 'Merk + model invoeren',
    client_search: 'Zoek een klant',
    filter: 'Filteren...',
    house: 'Voer het adres van de woning in',
    house_rental: 'Voer het adres van de huurwoning in',
    independent: 'Voer de naam van de onderneming in',
    motorbike: 'Merk + model invoeren',
    motorhome: 'Merk + model invoeren',
    search: 'Zoeken',
    select: 'Selecteren',
    van: 'Merk + model invoeren',
    boat: 'Merk + model invoeren',
    'push-scooter': 'Merk + model invoeren'
  },
  // This part is duplicated in Serverless and SW
  policy_types: {
    '1_toit_2_ages': '1_toit_2_ages',
    assistance: 'Bijstand',
    bicycle: 'Fiets',
    boat: 'Boot',
    car: 'Auto',
    car_bcp: 'Auto BCP',
    car_oldtimer: 'Oldtimer auto',
    civil_liability: 'Burgerlijke aansprakelijkheid',
    digital_nomad: 'Digitale apparaten',
    driver_safety: 'Veiligheid van de bestuurder',
    funeral: 'Uitvaartverzekering',
    health: 'Gezondheid',
    hospitalisation: 'Hospitalisatie',
    house: 'Woonverzekering',
    house_rent_guarantee: 'Onbetaalde huur',
    hunting: 'BA Jachtverzekering',
    independent_work_accident: 'Werkongevallen',
    legal_expenses: 'Rechtsbijstand Familiale',
    administrator_civil_liability: 'Bestuurdersaansprakelijkheid',
    life_group_insurance: 'Groepsverzekering leven',
    hospitalization_group_insurance: 'Groepsverzekering hospitalisatie',
    cyber_insurance: 'Cyberverzekering',
    legal_protection: 'Rechtsbijstand',
    life_cpti: 'Leven - POZ',
    life_eip: 'Leven - IPT',
    life_investment_fund: 'Leven - Beleggingsfonds',
    life_non_tax_savings: 'Leven - Niet-fiscale spaarverzekering',
    life_pension_long_term: 'Leven - Langetermijnsparen',
    life_pension_saving: 'Leven - Pensioensparen',
    life_plci: 'Leven - VAPZ',
    life_srd: 'Leven - Schuldsaldoverzekering',
    life_inami: 'Leven - Riziv',
    life_bonus_plan: 'Leven - Bonus Plan',
    motorbike: 'Motor',
    motorbike_oldtimer: 'Oldtimer motor',
    motorhome: 'Camper',
    organizer_civil_liability: 'BA Organisator',
    pet: 'Huisdieren',
    personal_liability: 'BA Familiale',
    professional_civil_liability: 'BA Beroep',
    professional_liability: 'BA Beroep',
    professional_legal_expenses: 'Rechtsbijstand zelfstandigen',
    objective_liability: 'Objectieve aansprakelijkheid',
    workers_compensation: 'Arbeidsongevallenverzekering'
  },
  splittings: [
    {
      label: 'Jaarlijks',
      value: 'yearly'
    },
    {
      label: 'Maandelijks',
      value: 'monthly'
    },
    {
      label: 'Driemaandelijks',
      value: 'trimestrial'
    },
    {
      label: 'Halfjaarlijks',
      value: 'half_yearly'
    }
  ],
  premiums_collection_methods: [
    {
      label: 'Maatschappij',
      value: 'direct_billing'
    },
    {
      label: 'Yago',
      value: 'broker_billing'
    }
  ],
  statuses: {
    accepted: 'Aanvaard door de klant',
    archived: 'Gearchiveerd/geannuleerd',
    finished: 'Beëindigd',
    ongoing_purchase: 'Aankoop gaande',
    in_progress_claims: 'Lopende claims',
    terminated_claims: 'Afgesloten claims',
    processed: 'Verwerkt',
    processed_for_another_date: 'Verwerkt voor een andere datum',
    solved: 'Betaald',
    validated: 'Gevalideerd',
    waiting_for_approval: 'Wacht op goedkeuring van de maatschappij',
    waiting_for_completion: 'Wacht op voltooiing',
    waiting_for_confirmation: 'Wacht op bevestiging van de wettelijke vermeldingen',
    waiting_for_contract: 'Afwachting contract',
    waiting_for_payment: 'Afwachting betaling',
    waiting_for_sending: 'Afwachting verzending',
    waiting_for_signature: 'Afwachting handtekening van de klant',
    waiting_for_validation: 'Afwachting validatie',
    written_off: 'Verloren (afgeschreven)',
    reported: 'Nieuwe claim',
    analysis: 'Analyse en afwachting opening',
    awaiting_expertise: 'Afwachting uitvoering expertise',
    expertise_dispute: 'Betwisting expertise',
    awaiting_report: 'Afwachting expertiserapport',
    awaiting_liability: 'Afwachting aansprakelijkheidsbepaling',
    liability_dispute: 'Betwisting aansprakelijkheid',
    legal_challenge: 'Juridische uitdaging',
    awaiting_closure: 'Wachten op afsluiting',
    voided: 'Geannuleerd',
    closed: 'Afgesloten'
  },
  street_box: 'bus {streetBox}',
  tables: {
    columns: {
      accidents: 'Schades',
      actions: 'Acties',
      amount: 'Bedrag',
      attestation: 'Attest',
      b2b_transfers: 'BOAR B2B Overdrachten',
      b2b_hospitalization_transfers: 'Hospi B2B Overdrachten',
      b2b_life_transfers: 'Leven B2B Overdrachten',
      broker_reference: 'Agentrekeningnummer',
      bundled_payment: 'Betalingsplan',
      cars: 'Auto',
      category: 'Categorie',
      cease_and_desist_at: 'Aanmaning',
      claim_statement: 'Schadeattest',
      claims: 'Claims',
      client: 'Klant',
      claim_type: 'Type claim',
      contract_number: 'Contractnummer',
      cover: 'Dekking',
      covering_from: 'Begin van de periode',
      covering_until: 'Einde van de periode',
      covers: "Gedekte risico's",
      date: 'Datum',
      details: 'Details',
      direction: 'Richting',
      document_type: 'Documenttype',
      documents: 'Documenten',
      due_at: 'Verschuldigd op',
      end_date: 'Vervaldatum',
      enforced_at: 'Van kracht op',
      event: 'Gebeurtenis',
      finished_at: 'Beëindigingsdatum',
      generic: 'Risico-object',
      gross_premium: 'Premies',
      houses: 'Woning',
      incident_date: 'Datum van de schade',
      iban: 'Rekeningnummer',
      id_card: 'ID',
      insurer: 'Verzekeraar',
      insurer_references: 'Schadereferenties van verzekeraars',
      invoice: 'Factuur',
      invoice_document: 'Aankoopfactuur',
      last_reminder_date: 'Datum laatste herinnering',
      last_reminder_step: 'Stap laatste herinnering',
      licence_plate: 'Kenteken',
      main: 'Beheer',
      motorbikes: 'Motor',
      name: 'Naam',
      number: 'Nummer',
      operator: 'Business developer',
      operator_short: 'Bizdev',
      owner: 'Owner',
      policy_number: 'Polisnummer',
      policy_type: 'Type polis',
      price_increase: 'Tariefverhoging',
      product: 'Product',
      reference: 'Referentie',
      reminders_count: 'Aantal herinneringen',
      risk_object: 'Risico-object',
      sent_at: 'Verstuurd op',
      sepa: 'Sepa',
      sepa_mandates: 'SEPA-mandaten',
      sinistrality: 'Schadehistoriek',
      start_date: 'Startdatum',
      statement: 'Verklaring',
      status: 'Status',
      stopping_reason: 'Reden voor beëindiging',
      stopping_schedule: 'Toekomstige opschorting/beëindiging',
      suspension_date: 'Opschortingsdatum',
      tags: {
        all_statuses: 'Alle statussen',
        danger: 'Te laat',
        success: 'Op tijd',
        warning: 'Binnenkort te laat'
      },
      termination_date: 'Beëindigingsdatum',
      terminations: 'Beëindigingen',
      transfers: 'BOAR Overdrachten',
      type: 'Type',
      updated: 'Bijgewerkt',
      updater: 'Actualisator',
      validated: 'Gevalideerd',
      won_at: 'Gewonnen op'
    }
  },
  tabs: {
    help: 'Help',
    new: 'Nieuw',
    ongoing: 'Lopend',
    past: 'Verleden'
  },
  timeline_activities: {
    detailedAt: 'Gedetailleerd op',
    paidAt: 'Betaald op',
    rejectedAt: 'Afgewezen op',
    validatedAt: 'Gevalideerd op',
    verifiedAt: 'Geverifieerd op'
  },
  tooltips: {
    search: 'Zoeken op naam, voornaam, e-mail, telefoon, wettelijke naam, btw-nummer, contractreferentie',
    tiny_search: 'Snelle zoekopdracht op naam, voornaam, e-mail, telefoon'
  },
  true: 'Ja',
  true_symbol: '✅',
  undefined: '?',
  desired_contract_start: {
    as_soon_as_possible: 'Zo snel mogelijk',
    in_1_month: 'Binnen 1 maand',
    in_3_months: 'Binnen 3 maanden',
    within_the_year: 'Binnen het jaar',
    looking_around: 'Ik oriënteer me',
    null: '-'
  },
  year: '- | 1 jaar | {years} jaar',
  empty_data: 'Geen gegevens',
  pdf_templates: {
    private_savings_information_duty: 'Informatieplicht PSP / LTS',
    physical_person_self_certification: 'Self Certification natuurlijk persoon AG',
    sepa_mandate_life_ag: 'SEPA mandaat leven AG',
    insurer_questionnaire: 'Vragenlijst Verzekeraar AG',
    medical_survey_less_than_30000: 'Medische vragenlijst AG als -30.000€',
    medical_survey_more_than_30000: 'Medische vragenlijst AG als +30.000€',
    sworn_statement: 'Verklaring op eer (Aedes / AXA / Vivium )',
    omnium_statement: 'Attest omnium (Aedes)'
  },
  pdf_template_categories: {
    life: 'Leven',
    property_and_casualty: 'BOAR'
  },
  warning_empty_chassis_number: 'Het chassisnummer is niet ingegeven.'
}
