export default {
  title: 'Schadegevallen',
  card_titles: {
    context: 'Omstandigheden',
    general_info: 'Verklaring van de klant',
    documents: 'Documenten'
  },
  elements: {
    incident_date: 'Datum van het schadegeval:',
    damage_cause: 'Oorzaak van de schade:',
    incident_location: 'Locatie van het schadegeval:',
    comments: 'Opmerkingen:',
    damage_description: 'Wat is de materiële schade?',
    damage_document_urls: "Documenten van het schadegeval (Schadeverslag, foto's van de schade)",
    repair_quote_url: 'Offerte voor reparatie',
    weather_event: 'Veroorzaakt door een weersgebeurtenis?',
    police_report_filed: 'Is er een proces-verbaal opgesteld door de politie?',
    police_report_number: 'Nummer van het proces-verbaal',
    inspection_garage_details:
      'Wat is de naam en het adres van de garage waar de schade aan je voertuig kan worden vastgesteld?',
    licence_plate: 'Wat is het kentekennummer van je voertuig?',
    vin: 'Wat is het chassisnummer van uw voertuig?',
    self_assessed_liability: 'Aansprakelijkheid voor het schadegeval:',
    vat_deducted: 'Heb je het voertuig gekocht met aftrek van BTW?',
    vat_deduction_percentage: 'Welk percentage van de BTW heb je afgetrokken?',
    housing_status: 'Ben je eigenaar of huurder?',
    repair_estimate_available: 'Heb je al een offerte voor de herstelling van de schade?',
    injured: 'Ben je gewond geraakt?',
    injury_description: 'Welke verwondingen heb je opgelopen?',
    injury_to_others: 'Zijn er andere personen gewond geraakt?',
    witness: 'Waren er getuigen aanwezig?',
    witness_information: 'Informatie over de getuige',
    assessed_liability: 'Aansprakelijkheid:',
    company_status: 'Bedrijfsstatus:',
    broker_claim_reference: 'Referentie producent:',
    claim_manager: 'Verantwoordelijke voor het bestand:',
    mission_sent_date: 'Datum verzonden missie:',
    report_number_reception_date: 'Datum PV ontvangen:',
    integration_date: 'Laatste integratie ASWeb bericht:',
    report_number: 'Minuten aantal:',
    main_domain: 'Hoofddomein:',
    tracking_url: 'URL elektronische tracking:',
    expertise_amount: 'Expertisebedrag exclusief BTW:',
    incident_description_code: 'Incident code 1 :',
    insurer_reference: 'Bedrijfsreferentie:'
  },
  damage_causes: {
    water: 'Waterschade (water, stookolie, schimmels)',
    vehicle_collision: 'Aanrijding tussen voertuigen',
    theft: 'Diefstal, vandalisme, overige',
    legal_protection: 'Rechtsbijstand',
    glass_breakage: 'Glasschade',
    other: 'Overige',
    unknown: 'Onbekend',
    weather: 'Weersgebeurtenis',
    object_collision_or_loss_of_control: 'Aanrijding met een object, controleverlies',
    collision: 'Aanrijding met een object',
    glass: 'Glasschade'
  },
  self_assessed_liabilities: {
    '50_50': '50-50: de fout ligt bij beide partijen',
    in_right: 'In recht',
    at_fault: 'In fout',
    unknown: 'Ik weet het niet'
  }
}
