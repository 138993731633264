<template>
  <div>
    <el-header data-test="nav_header">
      <div class="quote">
        <span class="text">{{ quote.text }}</span>
        <br />
        <span class="author">- {{ quote.from }}</span>
      </div>
      <div style="display: flex; justify-content: flex-end; align-items: center">
        <SearchBar
          @select="handleUserSelect"
          :complete="false"
          client-search-ref="clientSearch"
          style="line-height: 60px; width: 300px; margin-right: 10px"
          data-test="nav_user_select" />
        <a :href="reportBug" target="_blank">
          <el-button circle="circle" data-test="bug_report">
            <img src="@/assets/svg/icons/incident.svg" width="20"
          /></el-button>
        </a>
        <el-button @click="handleLogOut" circle="circle" data-test="logout_button">
          <img src="@/assets/svg/icons/logout.svg" width="15"
        /></el-button>

        <!-- <el-button -->
        <!--   :icon="Trophy" -->
        <!--   @click="displayDrawer" -->
        <!--   circle="circle" -->
        <!-- ></el-button> -->
      </div>
      <!--   <el-drawer -->
      <!--     :model-value="gamificationDrawerVisible" -->
      <!--     @close="handleClose" -->
      <!--     size="75%" -->
      <!--     ><template #default><GamificationStats -->
      <!--       v-if="gamificationLoaded" -->
      <!--     ></GamificationStats></template></el-drawer -->
      <!-- > -->
    </el-header>
  </div>
</template>

<script>
import GamificationStats from '@/components/gamification/GamificationStats'
import SearchBar from '@/components/inputs/SearchBar'
import { logout } from '@/services/auth'
import getQuote from '@/services/quotes'
import { Trophy } from '@element-plus/icons-vue'
import { operatorEmail } from '@/services/auth'
const quote = getQuote()

export default {
  name: 'AppHeader',
  computed: {
    Trophy() {
      return Trophy
    },
    reportBug() {
      return `https://it-helpdesk.paperform.co?operator_email=${operatorEmail()}&url=https://ardor.yago.be${
        this.$route.fullPath
      }`
    }
  },
  components: {
    SearchBar
  },
  data() {
    return {
      quote,
      user: {
        uuid: null,
        fullName: null,
        email: null,
        mobilePhone: null
      },
      gamificationDrawerVisible: false,
      gamificationLoaded: false
    }
  },
  methods: {
    handleLogOut() {
      logout()
    },
    handleUserSelect(client) {
      const {
        name,
        params: { uuid, vatNumber }
      } = this.$route

      if (name === 'users' && uuid === client.client_id) return
      if (name === 'company' && vatNumber === client.company_id) return

      if (client.company_id) {
        this.$router.push({ name: 'company', params: { vatNumber: client.company_id } })
      } else {
        this.$router.push({ name: 'users', params: { uuid: client.client_id } })
      }
    },
    displayDrawer() {
      this.gamificationDrawerVisible = true
      this.gamificationLoaded = true
    },
    handleClose() {
      this.gamificationDrawerVisible = false
    }
  }
}
</script>

<style lang="scss">
@import '@/style/element-variables.scss';

.quote {
  float: left;
  margin-top: 15px;
  color: lighten($--neutral-dark-bg, 30%);

  .text {
    font-size: 14px;
  }

  .author {
    font-size: 12px;
    margin-top: 15px;
    font-style: italic;
  }
}
</style>
