export default {
  company_details: {
    company: 'Entreprise',
    bank_info: 'Informations bancaires',
    bank_account: 'Compte bancaire',
    legal_address: 'Siège social',
    legal_form: 'Forme juridique',
    legal_name: 'Dénomination légale',
    nace_codes: "Secteur d'activité",
    primary: 'primaire',
    secondary: 'secondaire',
    history: "Historique d'activités",
    vat_number: 'Numéro de TVA',
    gross_margin: 'CA',
    employees: 'Employés',
    gain_loss: 'Bénéfices et pertes',
    turnover: 'Fonds propres',
    email: 'Adresse email principale'
  },
  title: 'Entreprises',
  vat_number: 'Numéro de TVA',
  legal_name: 'Dénomination légale',
  legal_address: 'Siège social',
  commercial_name: 'Nom commercial',
  new_company: 'Nouvelle entreprise',
  create_company: "Créer l'entreprise",
  company_created: 'Entreprise créée',
  company_already_exists: 'Cette entreprise a déjà été créée',
  company_not_created: "Un erreur est survenue, l'entreprise n'a pas été créée",
  vat_number_required: 'Le numéro de TVA est requis',
  invalid_vat_number: 'Le numéro de TVA doit être au format BE0123456789 ou BE 0123.456.789',
  tabs: {
    contracts: 'Contrats',
    details: 'Détails',
    members: 'Membres',
    terminations: 'Renons',
    transfers: 'Transferts',
    wins: 'Wins'
  },
  forms: {
    add_member_cta: 'Ajouter un membre',
    add_member_title: "Ajouter un membre à l'entreprise",
    user_not_found:
      "L'utilisateur recherché n'existe pas encore. Veuillez vous assurer que l'adresse mail soit correcte pour créer cet utilisateur.",
    user_found: "Voici l'addresse sélectionnée",
    selected_user: 'Utilisateur sélectionné',
    previous_step_cta: 'Revenir en arrière',
    next_step_cta: 'Continuer',
    create_member_cta: 'Enregistrer ce membre',
    add_roles_title: 'Attribuer des rôles au membre',
    add_roles_cta: 'Enregistrer les rôles',
    edit_positions_cta: 'Modifier les fonctions du membre',
    edit_roles_cta: 'Modifier les rôles du membre',
    edit_title_cta: 'Modifier le titre du membre',
    save_cta: 'Enregistrer',
    roles: {
      signatory: 'Signataire',
      unpaid_alert_receiver: 'Gestionnaire impayé',
      primary_contact_member: '1ère personne de contact'
    },
    positions: [
      {
        value: 'delegated_administrator',
        label: 'Administrateur délégué'
      },
      {
        value: 'administrator',
        label: 'Administrateur'
      },
      {
        value: 'ceo',
        label: 'CEO'
      },
      {
        value: 'cfo',
        label: 'CFO'
      },
      {
        value: 'accountant',
        label: 'Comptable'
      },
      {
        value: 'head_of_administration',
        label: "Responsable de l'administration"
      },
      {
        value: 'head_of_people',
        label: 'Responsable des ressources humaines'
      }
    ]
  },
  search_company: 'Chercher une entreprise'
}
