export default {
  add_remove_from_bundled_payment: 'Een contract toevoegen of verwijderen van het betalingsplan',
  amendment_details: 'Detail van de wijziging voor verandering van de motorfiets, ingevoerd door de klant',
  not_possible: 'Actie niet mogelijk op dit contract',
  card_titles: {
    actions: 'Acties',
    commercial: 'Commerciële informatie',
    general_info: 'Algemene informatie',
    house: 'Informatie over de woning',
    policy_owner: 'Informatie over de polisnemer',
    risk_object: 'Risico-objecten',
    timeline: 'Tijdlijn van gebeurtenissen:',
    vehicle: 'Informatie over het voertuig',
    verification: 'Verificatie van informatie'
  },
  coupling: {
    formula: {
      baloise_bundle_motorbike: 'Complete klant (Auto-Woning-Familiale verzekering) - Baloise Motor',
      baloise_car_motorbike: 'Auto - Motor Baloise',
      baloise_motorbike_motorbike: 'Motor - Motor Baloise'
    },
    role: {
      beneficiary: 'Begunstigde van de koppeling',
      primary: 'Basis van de koppeling'
    }
  },
  create_amendment: 'Een nieuw wijzigingsvoorstel registreren',
  create_car_contract: 'Nieuw autocontract 🚗',
  create_green_card: 'Een nieuw verzekeringsbewijs toevoegen',
  duplicate: 'De simulatie dupliceren',
  duplicate_confirmation: 'Weet je zeker dat je deze simulatie wilt dupliceren?',
  elements: {
    agent_account: 'Producent account:',
    bundled_payments_more_info: 'Beheer betalingsplannen',
    company_contract: 'Contract voor Onderneming:',
    coupled: 'Gekoppeld:',
    coupling_formula: 'Koppelingsformule:',
    coupling_role: 'Rol in de koppeling:',
    covers: 'Gedekte risico’s:',
    gross_premium: 'Premies:',
    commission: 'Commissies:',
    insurer: 'Verzekeraar:',
    policy_owner_name: 'Naam van de polisnemer:',
    policy_grouping_number: 'Nummer beleidsgroepering:',
    reference: 'Referentie:',
    operator: 'Verkoper:',
    start_date: 'Ingangsdatum:',
    splitting: 'Splitsing:',
    stopped_at: 'Datum van effectieve opzeg:',
    stopping_encoded_at: 'Datum van codering van de opzeg',
    stopping_encoded_by: 'Verantwoordelijke voor de opzeg:',
    stopping_reason: 'Reden voor de opzeg:'
  },
  link: 'link',
  linked_to_insurers: {
    label: 'Verzekeringsstatus',
    linked: 'Het contract is gekoppeld, verzekeringsberichten zijn ontvangen',
    not_linked: 'Het contract is niet gekoppeld, er zijn nog geen verzekeringsberichten ontvangen',
    terminated: 'Het contract is door de verzekeraar als beëindigd gemarkeerd'
  },
  new: 'Nieuw contract',
  part_of_bundled_payment: 'Onderdeel van een betalingsplan',
  prepopulation: {
    risk_name: 'Verzekering '
  },
  reinstate: 'Het contract herstellen',
  rectification: 'Het contract corrigeren',
  risk_object: {
    alias: 'Alias:',
    already_insured: 'Al verzekerd:',
    details: 'Details van het risico-object:',
    entry_into_circulation: '1ste inschrijving',
    info: 'Info:',
    new_car: 'Nieuwe auto:',
    type: 'Type:'
  },
  risk_objects: {
    bicycle: '🚲',
    boat: '⛵',
    car: '🚗',
    digital_bag: '📱',
    family: '👪',
    house: '🏠',
    house_rental: '🏘️',
    independent: '👔',
    motorbike: '🏍️',
    motorhome: '🚐',
    pension: '🧓',
    personal: '',
    travel: '✈️',
    van: '🚐',
    undefined: '',
    'push-scooter': '🛴',
    civil_liability: '👪',
    civil_liability_hunting: '',
    civil_liability_work_accident: '💼',
    e_reputation: '',
    hospitalization: '🏥',
    legal_expenses: '',
    moto: '🏍️',
    private_savings: '👴',
    quad: '',
    life_independant: '👴',
    travel_assistance: '✈️',
    truck: '🚚',
    b2b: '💼',
    life_ep_lt: '👴',
    life_ep_elt: '👴',
    assistance: '',
    driver_safety: '',
    axa_life_asrd: '🏘️',
    life_asrd: '🏘️',
    ag_life_savings_branch_21: '👴',
    null: '',
    oldtimer: '🚙',
    other: '',
    self_employed_savings: '👴'
  },
  risk_object_names: {
    bicycle: 'Fiets',
    boat: 'Boot',
    car: 'Auto',
    digital_bag: 'Digitaal',
    family: 'Familie',
    house: 'Woning (eigenaar)',
    house_rental: 'Woning (huurder)',
    independent: 'Zelfstandige',
    motorbike: 'Motor',
    motorhome: 'Camper',
    pension: 'Pensioen',
    'push-scooter': 'Step',
    personal: 'Persoonlijk',
    travel: 'Reis',
    van: 'Bestelwagen',
    civil_liability: 'Familialeverzekering',
    civil_liability_hunting: 'BA Jacht',
    civil_liability_work_accident: 'BA arbeidsongevallen',
    e_reputation: 'E-reputatie verzekering',
    hospitalization: 'Hospitalisatieverzekering',
    legal_expenses: 'Rechtsbijstand',
    moto: 'Motorverzekering',
    private_savings: 'Pensioenspaarverzekering',
    quad: 'Quadverzekering',
    life_independant: 'Pensioenspaarverzekering zelfstandige',
    travel_assistance: 'Reisbijstand',
    truck: 'Vrachtwagenverzekering',
    b2b: 'B2B verzekering',
    life_ep_lt: 'Langetermijnspaarverzekering',
    life_ep_elt: 'Pensioenspaarverzekering',
    assistance: 'Bijstand',
    driver_safety: 'Bestuurdersverzekering',
    axa_life_asrd: 'Schuldsaldoverzekering',
    life_asrd: 'Schuldsaldoverzekering',
    ag_life_savings_branch_21: 'Pensioenspaarverzekering',
    null: '',
    oldtimer: 'Oldtimerverzekering',
    other: 'Verzekering',
    self_employed_savings: 'Pensioenspaarverzekering zelfstandige'
  },
  save: 'Opslaan',
  splittings: {
    half_yearly: 'Halfjaarlijks',
    monthly: 'Maandelijks',
    null: '-',
    trimestrial: 'Driemaandelijks',
    unspecified: 'Niet gespecificeerd',
    yearly: 'Jaarlijks'
  },
  premium_collection_methods: {
    direct_billing: 'Maatschappij',
    broker_billing: 'Yago'
  },
  statuses: {
    simulation: 'Lopende aankopen',
    waitingForSignature: 'In afwachting handtekening',
    processed: 'Actieve contracten',
    archivedContracts: 'Opgezegde contracten',
    archived: 'Gearchiveerde simulaties'
  },
  stopping: {
    stopping_schedule: 'Gepland voor {date}'
  },
  indexation: {
    button_title: 'Indexering toepassen',
    modal_title: 'Weet je zeker dat je de indexering wilt toepassen?',
    base_premium: 'De oude premie was {base_premium}€.',
    indexed_premium: 'De nieuwe premie is {indexed_premium}€ na een indexering van {indexation_percentage}%.'
  },
  title: 'Te ondertekenen contracten',
  total_budget: 'Maandelijkse premie: ',
  aml_document_warning: 'Het AML-informatieblad moet alleen worden geüpload voor levensverzekeringen',
  product_guidance: 'Gelieve het juiste product aan te duiden; de klant zal dit zien in zijn klantzone.',
  alias_guidance: 'Let op, dit zal zichtbaar zijn in de klantzone van de klant. Wees nauwkeurig.',
  form_errors: {
    information_duty: 'Let op, alle door YAGO verkochte contracten moeten een informatieverplichting hebben.',
    client_card: 'Let op, elke klant moet minstens één klantenfiche hebben zodra hij klant wordt',
    quittance:
      'Let op, de klant ontvangt geen betalingsinstructies en riskeert in gebreke te blijven. Je mag dit alleen uitschakelen als je geen quittance van de maatschappij heeft ontvangen (het contract gaat over 3 maanden of meer in), of als de klant betaalt via bankdomiciliëring.'
  },
  contract_statuses: {
    archived: 'Gearchiveerd contract',
    covered: 'Contract getekend door de klant',
    full_package_bundled: 'Deel van multi-contractvoorstel',
    processed: 'In orde',
    suspended: 'Opgeschort',
    terminated: 'Opgezegd',
    voided: 'Nul et non avenu',
    waiting_for_completion: 'In afwachting persoonlijke gegevens van de klant',
    waiting_for_confirmation: 'In afwachting bevestiging',
    waiting_for_details: 'In afwachting details klant',
    waiting_for_details_and_verification: 'In afwachting bevestiging Baloise GEEN melding en details klant',
    waiting_for_payment: 'In afwachting bevestiging en betaling',
    waiting_for_selection: 'In afwachting keuze waarborgen',
    waiting_for_signature: 'In afwachting handtekening klant',
    waiting_for_validation: 'In afwachting bevestiging gegevens klant',
    waiting_for_validation_and_verification: 'In afwachting feedback Baloise en bevestiging gegevens klant.',
    waiting_for_verification: 'In afwachting bevestiging Baloise GEEN melding'
  },
  archived_contract_reasons: {
    already_terminated_contract_transferred: 'Overgedragen contract al geannuleerd',
    amendment: 'Aanhangsel / nieuw contract (pro-rata terugbetaling)',
    claim: 'Schade (pro-rata terugbetaling)',
    dispute: 'Geschil (manuele terugbetaling via Chargebee nodig)',
    e_commerce_rights: 'Herroeping 14 dagen (nietig verklaard, volledige terugbetaling laatste betaling)',
    mistake: 'Fout bij onderschrijving (nietig verklaard, volledige terugbetaling laatste betaling)',
    premium_increase: 'Tarief verhoging (pro-rata terugbetaling)',
    risk_disappearance: 'Verdwijning risico (pro-rata terugbetaling)',
    standard: 'Wens tot stopzetting (geen terugbetaling)',
    suspension: 'Opschorting (stopzetting betaling en pro-rata creditering van de premies)',
    unfavorable_transfer: 'Nadelige transfer (geen terugbetaling)',
    unpaid: 'Wanbetaling (na herinneringen, geen terugbetaling)',
    other: 'Andere (geen terugbetaling)',
    waiver_by_insurer: 'Afstand door de verzekeraar',
    policy_owner_death: 'Overlijden van de polishouder',
    policy_owner_bankruptcy: 'Faillissement van de polishouder',
    departure_abroad: 'Vertrek naar het buitenland',
    surrender: 'Afkoop (Leven)',
    obligation_to_another_insurer: 'Verplichting aan een andere verzekeraar',
    risk_aggravation: 'Risicoverzwaring',
    activity_cessation: 'Beëindiging van activiteit',
    cancellation: 'Opzegging'
  },
  create_zendesk_ticket: '+ Customer care request indienen',
  new_contract_audit: '🔍 Contract auditen',
  audit_info_link: 'https://www.notion.so/seraphin-be/R-ussir-ton-audit-perso-05a861bb1864482fade677d7d6e6bed5',
  transfer_contract: 'Contract overdragen',
  transfer_contract_modal: {
    title: 'Dit contract overdragen',
    confirmation_disclaimer: 'Weet u zeker dat u dit contract wilt overdragen aan {name}?',
    owner_type: 'Overdragen aan',
    owner_types: {
      individual: 'Particulier',
      self_employed: 'Zelfstandige',
      company: 'Bedrijf'
    },
    warning_self_employed:
      '⚠️ Let op: u heeft zojuist een zelfstandig profiel geselecteerd. Zorg ervoor dat de gekozen persoon correct is geregistreerd als rechtspersoon.',
    warning_b2b: '⚠️ Controleer of het geselecteerde bedrijf een eerste contactpersoon en een volledig postadres bevat.'
  },
  create_invoice: '+ Factuur opmaken',
  invoice_info_link: 'https://www.notion.so/Contrat-encaissement-YAGO-ea8be02a82eb4eae80addd07e518625d?pvs=24'
}
